import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout, {
  Player,
  ViewButtonNext,
  ViewButtonPrevious,
  ShareButtons,
  ViewModuleName,
  ViewChoice,
  ViewImgTop,
  Breadcrumbs,
} from '../components/layout';
import SEO from '../components/seo';
import Part3Videos from '../components/videos/part-3-videos.di';
import Sound from '../audio/di/voixoff_DI_33.mp3';
import Background from '../images/landscape-2.jpg';
import Borne3 from '../images/Bornes-3.png';

const ViewArticle = styled.article`
  display: flex;
  flex: 1 0 auto;
  flex-flow: column nowrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  overflow-y: auto;
  max-width: 100%;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;

  > * {
    display: flex;
  }
`;

const Part3_Videos = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO
      pathname="/di/part-3-videos"
      title="Découvre l’application FinDiETou et les vidéos du module “Mieux organiser mon activité”"
      description="Bienvenue dans la Partie 3 qui t’apprendra à Mieux organiser ton activité. Elle te donnera des conseils pour connaître ton environnement, faire le point sur ton activité et anticiper tes dépenses ainsi que tes bénéfices. Bonne formation !"
      quote="Bienvenue dans la Partie 3 qui t’apprendra à Mieux organiser ton activité. Elle te donnera des conseils pour connaître ton environnement, faire le point sur ton activité et anticiper tes dépenses ainsi que tes bénéfices. Bonne formation !"
      hashtag="#FinDiETou"
      banner="https://i.postimg.cc/ryBgbmPM/Module-3.jpg"
    />

    <ViewArticle>
      <Part3Videos />
      <ViewModuleName>
        <ViewChoice>
          <ViewImgTop src={Borne3} />
          Mieux organiser mon activité
        </ViewChoice>
      </ViewModuleName>
    </ViewArticle>
    <Player sound={Sound} />

    <Link to="/di/part-3-astuces">
      <ViewButtonPrevious color="warning">
        <i className="fa fa-chevron-left fa" />
      </ViewButtonPrevious>
    </Link>
    <Link to="/di/part-3-evaluation">
      <ViewButtonNext color="warning">
        <i className="fa fa-chevron-right" />
      </ViewButtonNext>
    </Link>
    <ShareButtons />
    <Breadcrumbs active={5} />
  </Layout>
);

export default Part3_Videos;
